jQuery(function($) {
  "use strict"; 

  $(window).scroll(function() {
    if ($(this).scrollTop() > 1) {
      $('header').addClass("sticky");
    } else {
      $('header').removeClass("sticky");
  }});


});